<div class="quick-order-table-container">
  <ng-container *ngIf="!displayDataScource">
    <div class="no-products-found">
      <div class="">
        <div class="check">
          <div class="empty-img"></div>
          <div class="text">
            {{ 'quickorder.noProductsAdded' | cxTranslate }}
          </div>
          <div class="textPartNum">
            {{ 'quickorder.startAddingProducts' | cxTranslate }}
          </div>
        </div>
      </div>
      <div class="box"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="displayDataScource">
    <div class="quick-order-table-body">
      <div class="quick-order-products-header">
        <div class="left-side">
          <div class="order-text">
            {{ 'quickorder.productList' | cxTranslate }}
          </div>
          <span class="textPartNum"
            >{{ selectedRowsCount }} / {{ totalCount }}
            {{ 'quickorder.productsSelcetd' | cxTranslate }}</span
          >
        </div>
        <div class="right-side">
          <button
            type="button"
            class="find-text"
            aria-label="Close"
            (click)="removeProducts()"
          >
            {{ 'quickorder.remove' | cxTranslate }}
          </button>
        </div>
      </div>
      <div class="box">
        <table class="waygate-quick-order-table">
          <thead>
            <tr>
              <th class="checkboxWidth">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? toggleSelectionAll($event) : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                ></mat-checkbox>
              </th>
              <th class="Partheight">
                {{ 'quickorder.partNum' | cxTranslate }}
              </th>
              <th class="descheight">
                {{ 'quickorder.availableStock' | cxTranslate }}
              </th>
              <th class="quantityheight">
                {{ 'quickorder.priceUsd' | cxTranslate }}
              </th>
              <th class="priceheight">
                {{ 'quickorder.estimatedShipdate' | cxTranslate }}
              </th>
            </tr>

            <tr></tr>
          </thead>

          <tbody>
            <ng-container
              *ngFor="
                let row of validatedBulkUploadList
                  | slice: startRange - 1 : endRange
              "
            >
              <!-- <h3>{{validatedBulkUploadList | json}}</h3> -->
              <tr
                [ngClass]="{ 'row-disabled': !row?.productAccessData?.isBuy }"
              >
                <td>
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? toggleSelection(row) : null"
                    [checked]="selection.isSelected(row)"
                  >
                  </mat-checkbox>
                </td>

                <td>
                  <div class="headTextBold">{{ row.partNum }}</div>
                  <div>{{ row.description }}</div>
                </td>
                <td>
                  <div>{{ 'quickorder.requiredQty' | cxTranslate }}</div>
                  <div class="headTextBold">{{ row.quantity }}</div>
                  <div>{{ 'quickorder.availableQty' | cxTranslate }}</div>
                  <ng-container
                    *ngIf="
                      row?.priceAvailabilityData?.availabilityDetails;
                      else noavlqty
                    "
                  >
                    <div
                      [ngClass]="
                        row?.quantity <
                        row?.priceAvailabilityData?.availabilityDetails[0]
                          ?.actualStockQty
                          ? 'headTextBold'
                          : 'colorChange'
                      "
                    >
                      {{
                        row?.priceAvailabilityData?.availabilityDetails[0]
                          ?.actualStockQty
                      }}
                    </div>
                  </ng-container>
                  <ng-template #noavlqty>
                    <div>{{ 'quickorder.availableQtyInfo' | cxTranslate }}</div>
                  </ng-template>
                </td>
                <td>
                  <div
                    *ngIf="
                      row?.priceAvailabilityData?.listPrice?.value &&
                      row?.priceAvailabilityData?.yourPrice?.value
                    "
                  >
                    <div>{{ 'quickorder.listPrice' | cxTranslate }}</div>
                    <div class="headTextBold">
                      {{ this.currency }} {{ this.currencySymbol
                      }}{{ row?.priceAvailabilityData?.listPrice?.value }}
                    </div>
                    <div>{{ 'quickorder.yourPrice' | cxTranslate }}</div>
                    <div class="headTextBold">
                      {{ this.currency }} {{ this.currencySymbol
                      }}{{ row?.priceAvailabilityData?.yourPrice?.value }}
                    </div>
                    <div (click)="viewBreakUp(row)">
                      <a
                        (click)="this.breakUp = !this.breakUp"
                        class="arw detail-view"
                        >{{ 'quickorder.viewBreakup' | cxTranslate }}
                        <i *ngIf="!breakUp" class="material-icons"
                          >keyboard_arrow_down</i
                        >
                        <i *ngIf="breakUp" class="material-icons"
                          >keyboard_arrow_right</i
                        ></a
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="
                      !row?.priceAvailabilityData?.listPrice?.value &&
                      !row?.priceAvailabilityData?.yourPrice?.value
                    "
                  >
                    {{ 'quickorder.priceNotAvialble' | cxTranslate }}
                  </div>
                  <div
                    *ngIf="
                      !row?.priceAvailabilityData?.listPrice?.value &&
                      !row?.priceAvailabilityData?.yourPrice?.value
                    "
                    (click)="viewBreakUp(row)"
                  >
                    <a
                      (click)="this.breakUp = !this.breakUp"
                      class="arw detail-view"
                      >{{ 'quickorder.viewBreakup' | cxTranslate }}
                      <i *ngIf="!breakUp" class="material-icons"
                        >keyboard_arrow_down</i
                      >
                      <i *ngIf="breakUp" class="material-icons"
                        >keyboard_arrow_right</i
                      ></a
                    >
                  </div>
                </td>
                <td>
                  <ng-container
                    *ngIf="row?.priceAvailabilityData?.availabilityDetails"
                  >
                    <ng-container *ngIf="!compareQty(row)">
                      <div>
                        {{
                          row?.priceAvailabilityData?.estShipData[0]?.shipDate
                        }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="compareQty(row)">
                      <ng-container *ngIf="notAvailibility(row)">
                        <div class="danger-out-of-stock">
                          {{ 'quickorder.noStockAvialble' | cxTranslate }}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!notAvailibility(row)">
                        <div
                          class=""
                          *ngIf="
                            row?.priceAvailabilityData?.estShipData[0]
                              ?.stockQty &&
                            row?.priceAvailabilityData?.estShipData[0]?.shipDate
                          "
                        >
                          <strong>
                            {{
                              row?.priceAvailabilityData?.estShipData[0]
                                ?.stockQty
                            }}
                          </strong>
                          {{ 'quickorder.qtyIsAvialbleOn' | cxTranslate }}
                          {{
                            row?.priceAvailabilityData?.estShipData[0]?.shipDate
                          }}
                        </div>
                        <ng-container
                          *ngIf="
                            row?.priceAvailabilityData?.leadTime > 0 &&
                            compareQty(row)
                          "
                        >
                          <div>
                            {{
                              'quickorder.leadTimeForRemainingQty' | cxTranslate
                            }}
                            <strong
                              >{{ row?.priceAvailabilityData?.leadTime }}
                              {{ 'quickorder.weeks' | cxTranslate }}</strong
                            >.
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="row?.priceAvailabilityData?.leadTime <= 0"
                        >
                          <div>
                            {{
                              'quickorder.leadTimeForRemainingQtyNotAvailable'
                                | cxTranslate
                            }}
                            <a [routerLink]="contactUsUrl">{{
                              'quickorder.contact' | cxTranslate
                            }}</a>
                            {{ 'quickorder.customerCare' | cxTranslate }}
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !row?.priceAvailabilityData?.availabilityDetails &&
                      !(
                        row?.priceAvailabilityData?.estShipData[0]?.stockQty &&
                        row?.priceAvailabilityData?.estShipData[0]?.shipDate
                      )
                    "
                  >
                    <div class="danger-out-of-stock">
                      {{ 'quickorder.stockInfoNotAvailable' | cxTranslate }}
                    </div>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="breakup-dropdown" *ngIf="breakUp">
      <div class="row-heading list-price-row m-0">
        <span class="price-menu-heading bold col-6">{{
          'quickorder.latestUpdates' | cxTranslate
        }}</span>
      </div>
      <div
        *ngIf="displayViewBreakUpItem.priceAvailabilityData.listPrice.value"
        class="row discounts"
      >
        <span class="price-menu-heading">{{
          'quickorder.listPrice' | cxTranslate
        }}</span>
        <span class="price-menu-content bold">
          {{ this.currency }} {{ this.currencySymbol }}
          {{ displayViewBreakUpItem?.priceAvailabilityData?.listPrice?.value }}
        </span>
      </div>
      <div
        *ngIf="
          displayViewBreakUpItem.priceAvailabilityData.yourPriceDiscount.value
        "
        class="row discounts"
      >
        <span class="price-menu-heading">{{
          'quickorder.discount' | cxTranslate
        }}</span>
        <span class="price-menu-content">
          {{
            displayViewBreakUpItem?.priceAvailabilityData?.discountPercentage
              | number: '1.2-2'
          }}%
        </span>
        <span class="price-menu-content bold">
          -{{ this.currency }} {{ this.currencySymbol }}
          {{
            displayViewBreakUpItem?.priceAvailabilityData?.yourPriceDiscount
              ?.value
          }}
        </span>
      </div>
      <div
        *ngIf="
          displayViewBreakUpItem.priceAvailabilityData.netSellingPrice.value
        "
        class="row total-discounts"
      >
        <span class="price-menu-heading bold">{{
          'quickorder.netPrice' | cxTranslate
        }}</span>
        <span class="price-menu-content bold">
          {{ this.currency }} {{ this.currencySymbol }}
          {{
            displayViewBreakUpItem?.priceAvailabilityData?.netSellingPrice
              ?.value
          }}
        </span>
      </div>
    </div>
    <div class="quick-order-pagination" *ngIf="totalCount > 10">
      <div class="page-size-container">
        <div>{{ 'quickorder.itemsPerPage' | cxTranslate }}</div>
        <div>
          <select name="" id="" (change)="pageSizeChanged($event)">
            <ng-container *ngFor="let value of pageSizes">
              <option [value]="value" [selected]="value == currentPageSize">
                {{ value }}
              </option>
            </ng-container>
          </select>
        </div>
        <div>{{ startRange }}-{{ endRange }} of {{ totalCount }}</div>
      </div>
      <div class="page-number-selector-container" *ngIf="totalPages > 1">
        <span
          class="material-icons"
          (click)="pageSelected(1)"
          [class.disabled]="currentPage == 1"
        >
          keyboard_double_arrow_left
        </span>
        <span
          class="material-icons"
          (click)="pageSelected(currentPage - 1)"
          [class.disabled]="currentPage == 1"
        >
          chevron_left
        </span>
        <div *ngIf="currentPage > 2" class="disabled">...</div>
        <ng-container *ngFor="let value of getPageArray(); let i = index">
          <ng-container
            *ngIf="
              currentPage == value ||
              currentPage - 1 == value ||
              currentPage + 1 == value
            "
          >
            <div
              class="pagination-numbers"
              [class.active]="value == currentPage"
              (click)="pageSelected(value)"
            >
              {{ value }}
            </div>
          </ng-container>
        </ng-container>

        <div *ngIf="currentPage < totalPages - 2" class="disabled">...</div>
        <span
          class="material-icons"
          (click)="pageSelected(currentPage + 1)"
          [class.disabled]="currentPage == totalPages"
        >
          chevron_right
        </span>
        <span
          class="material-icons"
          (click)="pageSelected(totalPages)"
          [class.disabled]="currentPage == totalPages"
        >
          keyboard_double_arrow_right
        </span>
      </div>
    </div>
  </ng-container>
</div>
