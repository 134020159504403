<div class="wrapper">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center heading">
      {{ 'searchPrivateFolder.heading' | cxTranslate }}
    </h2>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 text-center">
    <p>{{ 'searchPrivateFolder.para1' | cxTranslate }}</p>
    <div class="text-center">
      <img class="img-style" src="/assets/img/BynderLoginScreen1.png" />
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 mt-3 mb-3 text-center">
    <p>{{ 'searchPrivateFolder.para2' | cxTranslate }}</p>
  </div>
</div>
