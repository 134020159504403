import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { WaygateLandingComponent } from './waygate-landing/waygate-landing.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {
  ConfigModule,
  I18nModule,
  RoutingConfig,
  provideConfig,
  UrlModule,
  provideDefaultConfig,
  Config,
} from '@spartacus/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrackPoStatusComponent } from './track-po-status/track-po-status.component';
import { ImageSliderComponent } from './image-slider/imageSlider.component';
import { ShopCategoryComponent } from './shop-category/shop-category.component';
import { WaygateTechHqComponent } from './waygate-tech-hq/waygate-tech-hq.component';
import { NewArrivalsComponent } from './new-arrivals/new-arrivals.component';
import { FeaturedProdsComponent } from './featured-prods/featured-prods.component';
import { BestSellersComponent } from './best-sellers/best-sellers.component';
import { MatTabsModule } from '@angular/material/tabs';
import {
  DIALOG_TYPE,
  MediaModule,
  OutletModule,
  PageLayoutModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { WaygateHeaderComponent } from './waygate-header/waygate-header.component';
import { WaygateFooterComponent } from './waygate-footer/waygate-footer.component';
import { SearchboxModule } from '../header/searchbox/searchbox.module';
import { RouterModule } from '@angular/router';
import { MiniCartModule } from '../header/mini-cart/mini-cart.module';
// import { SiteLoginModule } from '../header/site-login/site-login.module';
// import { CustomerAccountComponentModule } from '../header/customer-account/customer-account.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LandingPagesModule } from '../landing/landing-pages.module';
import { WaygateMenuComponent } from './waygate-menu/waygate-menu.component';
import { SelectedCategoryPipe } from './waygate-menu/waygate.pipe';
import { WayagteMainStructureComponent } from './wayagte-main-structure/wayagte-main-structure.component';
import { WaygateBreadcrumbsComponent } from './waygate-breadcrumbs/waygate-breadcrumbs.component';
import { WaygateListingComponent } from './waygate-listing/waygate-listing.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WaygateGridProductComponent } from './waygate-listing/waygate-grid-product/waygate-grid-product.component';
import { WaygateListProductComponent } from './waygate-listing/waygate-list-product/waygate-list-product.component';
import { WaygateFacetsComponent } from './waygate-listing/waygate-facets/waygate-facets.component';
import { WaygateFacetComponent } from './waygate-listing/waygate-facets/waygate-facet/waygate-facet.component';
import { FacetSortPipe } from './waygate-listing/waygate-facets/facet-sort.pipe';
import { WaygateSearchDialogComponent } from './waygate-search-dialog/waygate-search-dialog.component';
import { WaygateProductDetailsComponent } from './waygate-product-details/waygate-product-details.component';
import { QuickOrderComponent } from './quick-order/quick-order.component';
import { QuickOrderPartsComponent } from './quick-order/quick-order-parts/quick-order-parts.component';
import { QuickOrderProductsComponent } from './quick-order/quick-order-products/quick-order-products.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';

import { AddToCartModule } from '../cart';
import { WaygateRemoveDialogComponent } from './quick-order/waygate-remove-dialog/waygate-remove-dialog.component';
import { FacetFilterPipe } from './waygate-listing/facet-filter.pipe';
import { WaygateRouterDialogComponent } from './quick-order/waygate-router-dialog/waygate-router-dialog.component';
import { WaygateMenuSecondaryComponent } from './waygate-menu-secondary/waygate-menu-secondary.component';
import { WaygateNavBarComponent } from './waygate-header/waygate-nav-bar/waygate-nav-bar.component';
import { WaygateCounterComponent } from './waygate-counter/waygate-counter.component';
import { WaygateAddToCartComponent } from './waygate-add-to-cart/waygate-add-to-cart.component';
import { WaygateCheckoutModalComponent } from './waygate-add-to-cart/waygate-checkout-modal/waygate-checkout-modal.component';
import { WaygateDatePicker } from './waygate-date-picker/waygate-date-picker.module';
import { WaygateOrdersComponent } from './waygate-orders/waygate-orders.component';
import { WaygateOrderDetailsComponent } from './waygate-orders/waygate-order-details/waygate-order-details.component';
import { WaygateCustomerAccountModule } from './waygate-customer-account/waygate-customer-account.module';
import { WaygateUserDetailsComponent } from './waygate-user-details/waygate-user-details.component';
import { WaygateCartCheckoutComponent } from './waygate-checkout/waygate-cart-checkout/waygate-cart-checkout.component';
import { WaygatePaymentComponent } from './waygate-checkout/waygate-cart-checkout/waygate-payment/waygate-payment.component';
import { WaygateShippingComponent } from './waygate-checkout/waygate-cart-checkout/waygate-shipping/waygate-shipping.component';
import { WaygateComplianceQuestionsComponent } from './waygate-checkout/waygate-cart-checkout/waygate-compliance-questions/waygate-compliance-questions.component';
import { WaygateNotificationsAttachmentsComponent } from './waygate-checkout/waygate-cart-checkout/waygate-notifications-attachments/waygate-notifications-attachments.component';
import { WaygateOrderSummaryComponent } from './waygate-checkout/waygate-cart-checkout/waygate-order-summary/waygate-order-summary.component';
import { WaygateEndCustomerAddressComponent } from './waygate-checkout/waygate-cart-checkout/waygate-end-customer-address/waygate-end-customer-address.component';
import { WaygateShiptoAddressComponent } from './waygate-checkout/waygate-cart-checkout/waygate-shipto-address/waygate-shipto-address.component';
import { WaygateSoldtoAddressComponent } from './waygate-checkout/waygate-cart-checkout/waygate-soldto-address/waygate-soldto-address.component';
import { WaygateAddressModalComponent } from './waygate-checkout/waygate-cart-checkout/waygate-address-modal/waygate-address-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { WaygateOrderConfirmationComponent } from './waygate-checkout/waygate-cart-checkout/waygate-order-confirmation/waygate-order-confirmation.component';
import { WaygateOrderProductComponent } from './waygate-orders/waygate-order-details/waygate-order-product/waygate-order-product.component';
import { WaygateCartDetailComponent } from './waygate-cart/waygate-cart-detail/waygate-cart-detail.component';
import { WaygateCartActionComponent } from './waygate-cart/waygate-cart-shared/waygate-cart-action/waygate-cart-action.component';
import { WaygateCartItemListComponent } from './waygate-cart/waygate-cart-shared/waygate-cart-item-list/waygate-cart-item-list.component';
import { CartDeleteDilogComponent } from '../cart/cart-shared/cart-delete-dilog/cart-delete-dilog.component';
import { SaveCartModelComponent } from '../cart/cart-shared/save-cart-model/save-cart-model.component';
import { WaygateCartOrderSummaryComponent } from './waygate-cart/waygate-cart-shared/waygate-cart-order-summary/waygate-cart-order-summary.component';
import { WaygateCartItemComponent } from './waygate-cart/waygate-cart-shared/waygate-cart-item/waygate-cart-item.component';
import { WaygateManageAccountComponent } from './waygate-manage-account/waygate-manage-account.component';
import { WaygateProfileComponent } from './waygate-manage-account/waygate-profile/waygate-profile.component';
import { WaygateCustomerAccountComponent } from './waygate-manage-account/waygate-customer-account/waygate-customer-account.component';
import { OrderSettingsComponent } from './waygate-manage-account/order-settings/order-settings.component';
import { WaygateNotificationEmailComponent } from './waygate-manage-account/waygate-notification-email/waygate-notification-email.component';
import { WaygateViewCartComponent } from './waygate-view-cart/waygate-view-cart.component';
import { WaygateViewCartDetailsComponent } from './waygate-view-cart-details/waygate-view-cart-details.component';
import { WaygateMyFavouritesComponent } from './waygate-my-favourites/waygate-my-favourites.component';
import { WaygateTrackOrderComponent } from './waygate-track-order/waygate-track-order.component';
import { WaygateCalibrationDataComponent } from './waygate-calibration-data/waygate-calibration-data.component';
import { GuestUserOrderDetailsComponent } from './waygate-track-order/guest-user-order-details/guest-user-order-details.component';
import { ConfigureProductModule } from './waygate-product-details/configure-product/configure-product.module';
import { ChooseBrandComponent } from '../choose-brand/choose-brand.component';
import { ChooseBrandListComponent } from './choose-brand-list/choose-brand-list.component';
import { ConfiguratorOverviewModalComponent } from './waygate-cart/waygate-cart-shared/waygate-cart-item/configurator-overview-modal/configurator-overview-modal.component';
// import { WaygateNotificationSliderComponent } from './waygate-header/waygate-notification-slider/waygate-notification-slider.component';
import { WaygateNotificationSliderComponent } from './waygate-header/waygate-notification-slider/waygate-notification-slider.component';
import { RegistrationModule } from '../waygate-tech-landing/registration/registration.module';
import { WayagteProductTypeFacetComponent } from './waygate-listing/waygate-facets/waygate-facet/wayagte-product-type-facet/wayagte-product-type-facet.component';
import { WaygateContactusComponent } from './waygate-contactus/waygate-contactus.component';
import { WaygateCompleteOrderComponent } from './waygate-cart/waygate-cart-shared/waygate-complete-order/waygate-complete-order.component';
import { PanametricsCalibrationDataComponent } from './waygate-calibration-data/panametrics-calibration-data/panametrics-calibration-data.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ApprovalCriteriaModalComponent } from './dashboard/approval-criteria-modal/approval-criteria-modal.component';
import { DruckCalibrationComponent } from './waygate-calibration-data/druck-calibration/druck-calibration.component';
import { ProductConfigurationModule } from './product-configurator/product-configuration.module';
import { ConfiguratorPriceSummaryModule } from './waygate-product-details/configurator-price-summary/configurator-price-summary.module';
import { RequestDetailComponent } from './dashboard/request-detail/request-detail.component';
import { RejectModalComponent } from './dashboard/reject-modal/reject-modal.component';
import { DS_DIALOG } from '../../core/dialog/dialog.config';
import { DateRangePickerModule } from '../../shared/components/date-range-picker/date-range-picker.module';
import { QuantityCounterModule } from '../../shared/components/quantity-counter';
import { DsRecaptchaModule } from '../../shared/components/recaptcha/recaptcha.module';
import { ClickOutsideDirectiveModule } from '../../shared/directives/click-outside.directive';
import { RmaPipesModule } from '../../shared/pipes/rma-pipes/rma-pipes.module';
import { DatePickerModule } from '../../shared/components/date-picker/date-picker.module';
import { FileUploadModule } from '../../shared/components/file-upload/file-upload.module';

@NgModule({
  declarations: [
    WaygateLandingComponent,
    TrackPoStatusComponent,
    ImageSliderComponent,
    ShopCategoryComponent,
    WaygateTechHqComponent,
    NewArrivalsComponent,
    FeaturedProdsComponent,
    BestSellersComponent,
    WaygateHeaderComponent,
    WaygateFooterComponent,
    WaygateMenuComponent,
    SelectedCategoryPipe,
    WayagteMainStructureComponent,
    WaygateBreadcrumbsComponent,
    WaygateListingComponent,
    WaygateGridProductComponent,
    WaygateListProductComponent,
    WaygateFacetsComponent,
    WaygateFacetComponent,
    FacetSortPipe,
    WaygateSearchDialogComponent,
    WaygateProductDetailsComponent,
    QuickOrderComponent,
    QuickOrderPartsComponent,
    QuickOrderProductsComponent,
    WaygateRemoveDialogComponent,
    FacetFilterPipe,
    WaygateRouterDialogComponent,
    WaygateMenuSecondaryComponent,
    WaygateNavBarComponent,
    WaygateCounterComponent,
    WaygateAddToCartComponent,
    ConfiguratorOverviewModalComponent,
    WaygateCheckoutModalComponent,
    WaygateOrdersComponent,
    WaygateOrderDetailsComponent,
    WaygateUserDetailsComponent,
    WaygateCartCheckoutComponent,
    WaygatePaymentComponent,
    WaygateShippingComponent,
    WaygateComplianceQuestionsComponent,
    WaygateNotificationsAttachmentsComponent,
    WaygateOrderSummaryComponent,
    WaygateEndCustomerAddressComponent,
    WaygateShiptoAddressComponent,
    WaygateSoldtoAddressComponent,
    WaygateAddressModalComponent,
    WaygateOrderConfirmationComponent,
    WaygateOrderProductComponent,
    WaygateCartOrderSummaryComponent,
    WaygateCartItemComponent,
    WaygateCartDetailComponent,
    WaygateCartActionComponent,
    WaygateCartItemListComponent,
    WaygateManageAccountComponent,
    WaygateProfileComponent,
    WaygateCustomerAccountComponent,
    OrderSettingsComponent,
    WaygateNotificationEmailComponent,
    WaygateViewCartComponent,
    WaygateViewCartDetailsComponent,
    WaygateMyFavouritesComponent,
    WaygateTrackOrderComponent,
    WaygateCalibrationDataComponent,
    WaygateNotificationSliderComponent,
    GuestUserOrderDetailsComponent,
    // WaygateRegistrationComponent,
    ChooseBrandComponent,
    ChooseBrandListComponent,
    WayagteProductTypeFacetComponent,
    DashboardComponent,
    RequestDetailComponent,
    RejectModalComponent,
    WaygateContactusComponent,
    WaygateCompleteOrderComponent,
    ApprovalCriteriaModalComponent,
    PanametricsCalibrationDataComponent,
    DruckCalibrationComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MediaModule,
    NgbModule,
    BrowserAnimationsModule,
    BrowserModule,
    I18nModule,
    MatTabsModule,
    PageLayoutModule,
    OutletModule,
    SearchboxModule,
    MiniCartModule,
    RouterModule,
    ClickOutsideDirectiveModule,
    RmaPipesModule,
    FileUploadModule,
    // SiteLoginModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTabsModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatSelectModule,
    LandingPagesModule,
    InfiniteScrollModule,
    SpinnerModule,
    MatTableModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTooltipModule,
    AddToCartModule,
    ConfigureProductModule,
    QuantityCounterModule,
    WaygateDatePicker,
    DatePickerModule,
    DateRangePickerModule,
    WaygateCustomerAccountModule,
    NgSelectModule,
    // searchFilterPipeModule,
    WaygateCustomerAccountModule,
    DsRecaptchaModule,
    UrlModule,
    RegistrationModule,
    ProductConfigurationModule,
    ConfiguratorPriceSummaryModule,
  ],
  providers: [
    provideDefaultConfig({
      i18n: {
        backend: {
          loadPath: '/assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: {
          common: [
            'waygate',
            'navigation-menu',
            'waygate-registration',
            'quickOrder',
            'track-order',
            'waygate-order',
            'customer-account',
          ],
          cart: [
            'cartDetails',
            'cartItems',
            'orderCost',
            'voucher',
            'saveForLaterItems',
            'clearCart',
            'validation',
          ],
        },
      },
    }),
    provideConfig({
      launch: {
        [DS_DIALOG.WAYGATE_SEARCH]: {
          inlineRoot: true,
          component: WaygateSearchDialogComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
        [DS_DIALOG.REMOVE_PRODUCTS]: {
          inlineRoot: true,
          component: WaygateRemoveDialogComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
        [DS_DIALOG.QUICK_ORDER_ROUTE_GAURD]: {
          inlineRoot: true,
          component: WaygateRouterDialogComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
        [DS_DIALOG.CONFIGURATOR_OVERVIEW_MODAL]: {
          inlineRoot: true,
          component: ConfiguratorOverviewModalComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
        [DS_DIALOG.WAYGATE_PDP_MODAL]: {
          inlineRoot: true,
          component: WaygateCheckoutModalComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
        [DS_DIALOG.CART_DELETE_DIALOG]: {
          inlineRoot: true,

          component: CartDeleteDilogComponent,

          dialogType: DIALOG_TYPE.DIALOG,
        },
        [DS_DIALOG.SAVE_CART_DIALOG]: {
          inlineRoot: true,
          component: SaveCartModelComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
        [DS_DIALOG.WAYGATE_ADDRESS_DIALOG]: {
          inlineRoot: true,
          component: WaygateAddressModalComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
        [DS_DIALOG.CSR_REQUEST_REJECT_ACTION]: {
          inlineRoot: true,
          component: RejectModalComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
        [DS_DIALOG.CSR_APPROVAL_CRITERIA_LIST]: {
          inlineRoot: true,
          component: ApprovalCriteriaModalComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
        [DS_DIALOG.COMPLETE_ORDER]: {
          inlineRoot: true,
          component: WaygateCompleteOrderComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
      },
    }),
    provideConfig(<Config>{
      productConfigurator: {
        updateDebounceTime: {
          input: 3000,
        },
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [WaygateNavBarComponent, WaygateCounterComponent],
})
export class WaygateTechModule {}
