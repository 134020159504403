export class GuestQuoteBakertabModel {
  address1: string;
  address2: string;
  cartCode: string;
  city: string;
  code: string;
  company: string;
  contactNumber: string;
  country: string;
  description: string;
  emailAddress: string;
  emailtype: string;
  endUserCompanyName: string;
  endUserCountryIso: string;
  endUserLine1: string;
  endUserLine2: string;
  endUserPostcode: string;
  endUserRegionIso: string;
  endUserRegionName: string;
  endUserTownCity: string;
  endUserType: string;
  errorCode: string;
  name: string;
  postalCode: string;
  region: string;
  userName: string;
}
