<ng-container *ngIf="product$ | async as product; else technicalError">
  <div class="configure-page-headerbar">
    <div class="container">
      <div class="left-items">
        <span id="title">Configure Product</span>
        <span id="pipe">|</span>
        <span id="prod-title">{{ product.name }}</span>
      </div>
      <div class="right-items" *ngIf="configuration$ | async as configuration">
        <button
          (click)="resetData(configuration.configId)"
          [ngClass]="{
            'reset-btn': resetFlag,
            'reset-container-disabled': !resetFlag,
          }"
        >
          <mat-icon>refresh</mat-icon>
          <div class="reset-font">Reset</div>
        </button>
        <button id="download-btn" (click)="downloadCSV(configuration.configId)">
          <div class="download-csv-font">
            <span><img src="../../../../../assets/img/Download_csv.svg"></span>
            <div class="download-csv-label">Download CSV</div>
          </div>
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #technicalError>
  <div *ngIf="technicalErrorFlag">
    <div class="error-message">
      <h4>Technical Error , Please reach out to technical support team</h4>
    </div>
  </div>
</ng-template>
<br /><br />
