import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-waygate-landing',
  templateUrl: './waygate-landing.component.html',
  styleUrls: ['./waygate-landing.component.scss'],
})
export class WaygateLandingComponent implements OnInit {
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
