import { Injectable, inject } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  CanActivateFn,
} from '@angular/router';
import {
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
} from '@spartacus/core';

@Injectable({ providedIn: 'root' })
export class CustomLoginGuardClass {
  constructor(
    protected authService: AuthService,
    private globalMessageService: GlobalMessageService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    this.authService.isUserLoggedIn().subscribe((res) => {
      if (res) {
        return true;
      } else {
        this.globalMessageService.add(
          'Page can only be accessed by logged-in user',
          GlobalMessageType.MSG_TYPE_ERROR,
          10000
        );
        this.router.navigate(['/login']);
        return true;
      }
    });
    return true;
  }
}
export const CustomLoginGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  return inject(CustomLoginGuardClass).canActivate(route);
};
