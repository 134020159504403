<div class="container-main">
  <!-- class="container-fluid" -->
  <header class="header">
    <div class="logo" (click)="logoClick()">
      <img
        src="../../../../../assets/img/blacktext_logo.svg"
        alt="Baker Hughes Logo"
      />
    </div>
    <nav class="right-section">
      <div class="language-selector">
        <span>
          <img src="../../../../../assets/img/glob.svg" alt="select language" />
        </span>
        <bh-dropdown
          class="langDD nav-content link-anchor"
          isFluid="true"
          isSmall="true"
          menuWidth="fluid"
          [style.width]="ddWidth"
          unselectable="false"
          [menuItems]="props"
          [value]="currentLanguage"
          (selected)="onLanguageChange($event)"
        ></bh-dropdown>
      </div>
      <a href="/login" class="login">
        <span class="login-icon">
          <img src="../../../../../assets/img/login.svg" alt="login" />
        </span>
        <span class="typography12">Login</span>
      </a>
    </nav>
  </header>
  <div class="row reg-success">
    <section class="msg-section">
      <h3>
        <img
          class="img-icon"
          src="../../../../../assets/img/BakerHughes_Icons_Email.png"
        />{{ 'registration.registerSuccessHeader' | cxTranslate }}
      </h3>
      <p [innerHTML]="'registration.registerSuccess' | cxTranslate"></p>
    </section>
  </div>
  <footer>
    <div class="footer-left">©2024 Baker Hughes Company</div>
    <div class="footer-right">
      <a href="https://www.bakerhughes.com/company/about-us" target="_blank"
        >About us</a
      >
      <a (click)="onClickCookie($event)" target="_blank" class="cursor"
        >Cookies</a
      >
      <a (click)="openTerms()" target="_blank" class="cursor">Terms of use</a>
      <a href="https://www.bakerhughes.com/privacy" target="_blank">Privacy</a>
    </div>
  </footer>
</div>
