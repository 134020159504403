<ds-recaptcha></ds-recaptcha>
<div *ngIf="loadingFlag; else loadSpinner">
  <div class="mt-3 mb-3 status" *ngIf="submitSuccess && !errorMsg">
    <span class="material-icons success-info"> check_circle</span>
    <div class="success-content">
      <span class="top-success">{{
        'feedback.successSubmit' | cxTranslate
      }}</span>
      <span class="material-icons-outlined clear-icon" (click)="clear()">
        clear
      </span>
    </div>
  </div>
  <div class="mt-3 mb-3 status error-border" *ngIf="submitSuccess && errorMsg">
    <span class="material-icons success-info error-color"> check_circle</span>
    <div class="success-content">
      <span class="top-success error-color">{{
        'feedback.errorMessage' | cxTranslate
      }}</span>
      <span
        class="material-icons-outlined clear-icon error-color"
        (click)="clear()"
      >
        clear
      </span>
    </div>
  </div>
  <div class="header">
    <div class="feedback-details">
      {{ 'feedback.feedbackDetails' | cxTranslate }}
    </div>
    <hr class="line-size" />
    <div class="feedback-form">
      <div class="row">
        <div class="col-12">
          <div class="subject-lable">
            <bh-text-input
              type="text"
              required
              label="{{ 'feedback.subject' | cxTranslate }}"
              name="subject"
              maxlength="40"
              [value]="subject"
              [message]="error.subject"
              [attr.error]="error.subject ? error.subject : null"
              (keyup)="onChange($event, 'subject')"
            >
            </bh-text-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="">
            <bh-text-input
              type="text"
              required
              label="{{ 'feedback.name' | cxTranslate }}"
              name="name"
              maxlength="40"
              [value]="name"
              [message]="error.name"
              [attr.error]="error.name ? error.name : null"
              (keyup)="onChange($event, 'name')"
            >
            </bh-text-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="">
            <bh-text-input
              type="text"
              required
              label="{{ 'feedback.phoneNumber' | cxTranslate }}"
              name="phoneNumber"
              maxlength="10"
              [value]="phoneNumber"
              [message]="error.phoneNumber"
              [attr.error]="error.phoneNumber ? error.phoneNumber : null"
              (keyup)="onChange($event, 'phoneNumber')"
            >
            </bh-text-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="">
            <bh-text-input
              type="text"
              required
              label="{{ 'feedback.emailAddress' | cxTranslate }}"
              name="emailAddress"
              maxlength="40"
              [value]="emailAddress"
              [message]="error.emailAddress"
              [attr.error]="error.emailAddress ? error.emailAddress : null"
              (keyup)="onChange($event, 'emailAddress')"
            >
            </bh-text-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <span class="custom">*</span>
          <bh-text-area
            required
            label="{{ 'feedback.message' | cxTranslate }}"
            required="true"
            messageType="count"
            maxcharacters="300"
            name="message"
            [value]="message"
            [message]="error.message"
            [attr.error]="error.message ? error.message : null"
            (keyup)="onChange($event, 'message')"
            (keypress)="stopCount($event)"
            #textval
            (paste)="myFunction($event)"
            (focusout)="trimText()"
          ></bh-text-area>
        </div>
      </div>

      <div class="attachement-text">
        <label class="attachement-margin">{{
          'feedback.attachement' | cxTranslate
        }}</label>

        <div
          class="attachement"
          fileDragDrop
          (filesChangeEmiter)="onFileChange($event)"
        >
          <div class="attached-file">
            {{ 'feedback.clipboard' | cxTranslate }}
          </div>
        </div>
        <div *ngIf="!dragdropfile" class="file-upload-error">
          {{ 'feedback.dragdropvalidfile' | cxTranslate }}
        </div>
      </div>

      <div class="attachement-text upload">
        <div class="select-file">{{ 'feedback.selectFile' | cxTranslate }}</div>
        <div>
          <div class="col-md-7">
            <div class="row">
              <div class="file-upload">
                <ds-file-upload
                  class="file"
                  [label]="'feedback.uploadFile' | cxTranslate"
                  [maxLength]="1"
                  [maxSizeInMB]="30"
                  [showIcon]="true"
                  [icon]="'backup'"
                  [uploadId]="'notification'"
                  [allowedFileExtensions]="ALLOWED_EXTENSIONS"
                  (selectedFiles)="selectedFiles($event)"
                  >upload</ds-file-upload
                >
                <div class="file-list">
                  <ds-file-upload-progress
                    *ngFor="let file of files"
                    [layout]="layouts.LIST"
                    [file]="file"
                    (deletedFiles)="deletedFiles($event)"
                    (fileUploadCode)="fileUploadCode($event)"
                    [uploadParams]="uploadParams"
                    [deleteParams]="deleteParams"
                    [uploadUrl]="uploadUrl"
                    [deleteUrl]="deleteUrl"
                    [showProgress]="true"
                    [entryNumber]="1"
                  ></ds-file-upload-progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="submit">
        <div class="col-md-12">
          <bh-button
            type="secondary"
            [attr.disabled]="showLoading ? true : null"
            [attr.loading]="showLoading ? true : null"
            label="{{ 'feedback.submit' | cxTranslate }}"
            class="submit"
            (click)="onSubmit()"
          >
          </bh-button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadSpinner>
  <div class="feedback-spinner cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
