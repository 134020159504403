<ng-container>
  <div class="sso-page">
    <div class="unauthorized-error">
      <div class="container">
        <div class="unauth-img"></div>
        <h1>Insufficient rights</h1>
        <p>
          403: You don’t have the rights to access this page.
          <br />
          If you believe this is an error,
          <a (click)="redirectToContactUs()">let us know</a>
        </p>
      </div>
    </div>
  </div>
</ng-container>
