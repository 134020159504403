<div class="cx-modal-container">
  <div class="cx-modal-content">
    <div #dialog>
      <!-- Modal Header -->
      <ng-container>
        <div class="cx-dialog-header modal-header">
          <div class="cx-dialog-title modal-title">
            {{ 'buyCart.discardCart' | cxTranslate }}
          </div>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeModal()"
          >
            <i class="material-icons">close</i>
          </button>
        </div>
        <!-- Modal Body -->
        <div class="cx-dialog-body modal-body">
          <div class="row">
            <div class="cx-dialog-info col-md-12">
              <div class="dialog-product-code">
                {{ 'buyCart.itemsRepeatToYourCart' | cxTranslate }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 action-container">
              <bh-button
                type="secondary"
                label="{{ 'buyCart.cancel' | cxTranslate }}"
                class="mr-2"
                (click)="closeModal()"
              >
              </bh-button>
              <bh-button
                cxAutoFocus
                [type]="'primary'"
                label="{{ 'buyCart.proceed' | cxTranslate }}"
                (click)="closeModal(true)"
              >
              </bh-button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
