<div class="cx-modal-container">
  <div class="cx-modal-content">
    <div #dialog>
      <!-- Modal Header  *ngIf="(loaded$ | async) || modalIsOpen; else loading" -->
      <ng-container *ngIf="(loaded$ | async) || modalIsOpen; else loading">
        <div class="cx-dialog-header modal-header">
          <div class="cx-dialog-title modal-title">
            {{'rma-form.itemAddedToReturnCart' | cxTranslate}}
            <!-- {{ (increment ? 'addToCart.itemsIncrementedInYourCart' :
        'addToCart.itemsAddedToYourCart' ) | cxTranslate }} -->
          </div>
          <!-- <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="dismissModal('Cross click')"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button> -->
        </div>
        <!-- Modal Body entry$ | async as entry-->
        <div class="cx-dialog-body modal-body" *ngIf="product">
          <div class="cx-dialog-row">
            <div class="cx-dialog-item">
              <div class="row entry-item-row">
                <div class="col-md-5">
                  <cx-media
                    class="cx-product-image"
                    [ngClass]="product.images?.PRIMARY ? '' : 'is-missing'"
                    [container]="product.images?.PRIMARY"
                    format="product"
                    [alt]="product.summary"
                  ></cx-media>
                </div>

                <div class="col-md-7 product-desc-col">
                  <div class="dialog-product-code">{{ product.code }}</div>
                  <a
                    [title]="product.name"
                    [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
                    class="dialog-product-name"
                    [innerHTML]="product.name"
                  ></a>
                </div>
              </div>
            </div>
            <!-- Separator -->
            <div class="cx-dialog-separator"></div>
            <!-- Total container -->
            <div class="cx-dialog-actions">
              <!-- <div class="cx-dialog-total" *ngIf="cart$ | async as cart">
            <div>
              {{ 'cartItems.cartTotal' | cxTranslate: { count:
              cart.deliveryItemsQuantity } }}
            </div>

            <div>{{ cart.subTotal?.formattedValue }}</div>
          </div> -->

              <!-- Actions [routerLink]="{ cxRoute: 'cart' } | cxUrl" -->
              <div class="cx-dialog-buttons">
                <!-- <a
              [routerLink]="['/cart']"
              class="bh-button motion--normal bh-button--type-primary mb-3"
              autofocus
              >{{ 'addToCart.viewCart' | cxTranslate }}</a
            > -->
                <bh-button
                  [type]="'secondary'"
                  [label]="'rma-form.addDetails' | cxTranslate"
                  (click)="dismissModal('closed')"
                  fluid
                ></bh-button>
                <bh-button
                  class="mt-3"
                  [type]="'primary'"
                  [label]="'rma-form.goToReturnCart' | cxTranslate"
                  (click)="redirectToCart()"
                  fluid
                ></bh-button>
                <!-- <a
              [routerLink]="['/cart']"
              class="bh-button motion--normal bh-button--type-secondary"
              (click)="dismissModal(true)"
              >Proceed to complete form</a
            > -->
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #loading>
        <div class="cx-dialog-header modal-header">
          <div class="cx-dialog-title modal-title">
            {{ 'addToCart.updatingCart' | cxTranslate }}
          </div>
        </div>
        <!-- Modal Body -->
        <div class="cx-dialog-body modal-body">
          <div class="cx-dialog-row">
            <div class="col-sm-12"><cx-spinner></cx-spinner></div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
