export enum LANGUAGES {
  en = 'English',
  de = 'Deutsch',
  es = 'Español',
  fr = 'Français',
  pt = 'Português do Brasil',
  ru = 'Русский',
  ja = '日本語',
  zh = '中文',
}
