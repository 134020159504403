import { HttpErrorModel } from '@spartacus/core';
import { ProductCategory } from '../../model/product-categories.model';
import * as fromProductCategoriesAction from '../actions/product-categories.action';

export interface ProductCategoriesState {
  productCategories: ProductCategory[] | HttpErrorModel;
}

const initalState: ProductCategoriesState = {
  productCategories: [],
};

export function productCategoriesReducer(
  state = initalState,
  action: fromProductCategoriesAction.ProductCategoriesActions
) {
  switch (action.type) {
    case fromProductCategoriesAction.FETCH_ALL_CATEGORIES:
      return {
        ...state,
      };
    case fromProductCategoriesAction.FETCH_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        productCategories: {
          ...state.productCategories,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
