<div class="cx-modal-container">
  <div class="cx-modal-content">
    <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">
        <span>Session Timeout</span>
      </div>
    </div>
    <div class="cx-dialog-body modal-body">
      <div class="row">
        <div class="cx-dialog-info col-md-12">
          <p>
            You'll be logged out in {{ secondsToDday }} seconds. Click on Stay
            to continue in logged in mode.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 action-container">
          <bh-button
            class="mr-2"
            [type]="'secondary'"
            [label]="'Logout'"
            (click)="closeModal('logout')"
          ></bh-button>
          <bh-button
            [type]="'primary'"
            [label]="'Stay'"
            (click)="closeModal('stay')"
          ></bh-button>
        </div>
      </div>
    </div>
  </div>
</div>
