<div class="rma-cart-summary" *ngIf="cart">
  <div class="rma-cart-info">
    <span>{{ 'rma-cart.totalItem' | cxTranslate }} </span>
    <span class="rma-cart-value">{{
      (cart?.returnsCartData | rmaProduct)?.length
    }}</span>
  </div>
  <div
    *ngIf="cart?.totalReturnCartPriceDiscount?.value > 0"
    class="rma-cart-info"
  >
    <span class="bold">{{ 'rma-cart.totalDiscount' | cxTranslate }} </span>
    <span class="rma-cart-value">{{
      cart?.totalReturnCartPriceDiscount?.formattedValue
    }}</span>
  </div>
  <div class="rma-cart-info">
    <span class="bold">{{ 'rma-cart.totalEstimate' | cxTranslate }} </span>
    <span
      *ngIf="cart?.totalReturnCartPrice?.value > 0; else toBeQuoted"
      class="rma-cart-value"
      >{{ cart?.totalReturnCartPrice?.formattedValue }}</span
    >
  </div>
  <div class="rma-cart-info">
    <span> {{ 'rma-cart.starInfo' | cxTranslate }}</span>
  </div>
  <ng-container *ngIf="currentUserAccess$ | async as userType">
    <div *ngIf="checkRolesForCheckout(userType)" class="rma-cart-btn">
      <bh-button
        class="rma-cart-btn"
        fluid
        (click)="goToCheckout()"
        [attr.disabled]="checkDisable()"
        [type]="'primary'"
        [label]="'rma-cart.proceedToCheckout' | cxTranslate"
      ></bh-button>
    </div>
  </ng-container>
</div>

<ng-template #toBeQuoted>
  <span class="rma-cart-value">{{ 'rma-cart.toBeQuoted' | cxTranslate }}</span>
</ng-template>
