import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OccEndpointsService } from '@spartacus/core';
import { ApiService } from '../../http/api.service';

import * as fromProductCategoriesAction from '../store/actions/product-categories.action';
import * as fromProductCategoriesSelectors from '../store/selectors/product-categories.selector';
import { DsAppState } from '../../../store/app.reducer';

@Injectable({ providedIn: 'root' })
export class ProductCategoriesService {
  constructor(
    private apiService: ApiService,
    private store: Store<DsAppState>,
    private occEndpointsService: OccEndpointsService
  ) {}

  loadDefaultProductCategories(userId: string) {
    this.store.dispatch(
      new fromProductCategoriesAction.FetchAllProductCategories(userId)
    );
  }

  fetchDefaultProductCategories() {
    return this.store.select(
      fromProductCategoriesSelectors.fetchProductCategories
    );
  }

  getProductCategoryFromId(id: string) {
    let url = this.occEndpointsService.buildUrl('/users/current/' + id);
    return this.apiService.getData(url);
  }
}
