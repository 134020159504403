<div>
  <div *ngIf="error">
    {{ error?.message }}
  </div>
  <div *ngIf="uploadResponse.status === 'error'">
    {{ uploadResponse.message }}
  </div>
  <div *ngIf="uploadResponse.status === 'progress'">
    <div
      role="progressbar"
      [style.width.%]="uploadResponse.message"
      aria-valuenow="25"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      {{ uploadResponse.message }}%
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <input type="file" name="avatar" (change)="onFileChange($event)" />
    <button type="submit">{{ 'custom-upload.upload' | cxTranslate }}</button>
  </form>
</div>

<div
  *ngIf="!files.length"
  class="col-12 rmpm dropzone"
  appDragDrop
  (files)="filesDropped($event)"
>
  <div class="text-wrapper">
    <div class="centered">{{ 'custom-upload.dropfile' | cxTranslate }}</div>
  </div>
</div>
<!--droped image preview-->
<div *ngFor="let file of files">
  <img *ngIf="file" [src]="file.url" width="100px" height="100px" />
</div>

<button type="button" (click)="upload()">
  {{ 'custom-upload.uploadimage' | cxTranslate }}
</button>
