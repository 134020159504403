<div class="cx-modal-container guest-track">
  <div class="cx-modal-content">
    <div class="cx-modal-header">
      <div></div>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal('close')"
      >
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="cx-dialog-body">
      <img class="image-width" src="/assets/img/trackerstatus.png" />
    </div>
  </div>
</div>
