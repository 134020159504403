import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuickOrderService {
  public $getOrderBulk = new BehaviorSubject(null);
  public navigationDataSource = new BehaviorSubject(false);

  constructor() {}

  sendQuickOrderList(value: any) {
    this.$getOrderBulk.next(value);
  }

  getQuickOrderList() {
    return this.$getOrderBulk.asObservable();
  }

  setNavigation(flag) {
    this.navigationDataSource.next(flag);
  }
  getNavigation() {
    return this.navigationDataSource.asObservable();
  }
}
