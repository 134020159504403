<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <ds-order-summary [cart]="cart"></ds-order-summary>
    <button
      [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
      *ngIf="entries.length"
      class="btn btn-primary btn-block"
      type="button"
    >
      {{ 'cartDetails.proceedToCheckout' | cxTranslate }}
    </button>
  </ng-container>
</ng-container>
