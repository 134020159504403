<div class="row m-auto" *ngIf="loadingFlag; else loading">
  <div class="col-md-8">
    <div class="section-container">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isAccountCollapsed
              ? 'accordion-toggle'
              : 'accordion-toggle-collapsed'
          "
          (click)="isAccountCollapsed = !isAccountCollapsed"
          [attr.aria-expanded]="!isAccountCollapsed"
          aria-controls="accountDetails"
        >
          {{ 'titles.customerAccount' | cxTranslate }}
        </div>
        <hr />
        <app-customer-accountrma
          id="accountDetails"
          [ngbCollapse]="isAccountCollapsed"
          [rmaSoldToAddress]="this.rmaSoldToAddress"
          [customerAccount]="this.customerAccount"
        >
        </app-customer-accountrma>
      </div>
    </div>
    <div class="section-container">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isPaymentCollapsed
              ? 'accordion-toggle'
              : 'accordion-toggle-collapsed'
          "
          (click)="isPaymentCollapsed = !isPaymentCollapsed"
          [attr.aria-expanded]="!isPaymentCollapsed"
          aria-controls="paymentDetails"
        >
          {{ 'titles.payment' | cxTranslate }}
        </div>
        <hr />
        <app-payment-detailsrma
          id="paymentDetails"
          [ngbCollapse]="isPaymentCollapsed"
          [paymentTerm]="this.paymentTerm"
          [businessEntity]="this.businessEntity"
          [locationList]="this.locationList"
          [paymentType]="this.paymentType"
          [returnList]="this.returnList"
          [cartTotal]="this.cartTotal"
          (setPaymentData)="getPaymentInfo($event)"
        >
        </app-payment-detailsrma>
      </div>
    </div>
    <div class="section-container" *ngIf="isPayerDisabled">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isPayerCollapsed ? 'accordion-toggle' : 'accordion-toggle-collapsed'
          "
          (click)="isPayerCollapsed = !isPayerCollapsed"
          [attr.aria-expanded]="!isPayerCollapsed"
          aria-controls="shippingDetails"
        >
          {{ 'titles.payer' | cxTranslate }}
        </div>
        <hr />
        <app-payer-detailsrma
          id="PayerDetails"
          [ngbCollapse]="isPayerCollapsed"
          [rmaPayerAddress]="this.rmaPayerAddress"
          [inkoTerm]="this.inkoTerm"
          [deliveryOption]="this.deliveryOption"
          [rmaCollectList]="this.rmaCollectList"
          [rmaPrePayAddList]="this.rmaPrePayAddList"
          [rmaData]="this.rmaData"
          (setShippinglData)="getPayerInfo($event)"
          (checkRMAPayerAddressVal)="checkRMAPayerAddressVal($event)"
        >
        </app-payer-detailsrma>
      </div>
    </div>
    <!-- <div class="section-container">
      <div class="section-container-buy">
        <div [ngClass]="isbilltoaddressCollapsed ? 'accordion-toggle' : 'accordion-toggle-collapsed'"
          (click)="isbilltoaddressCollapsed = !isbilltoaddressCollapsed"
          [attr.aria-expanded]="!isbilltoaddressCollapsed" aria-controls="shippingDetails">
          {{"titles.billtoaddress" | cxTranslate}}
        </div>
        <hr />
        <app-billto-addressrma id="billtoAddress" [ngbCollapse]="isbilltoaddressCollapsed"
          [rmaShippingAddress]="this.rmaShippingAddress" [inkoTerm]="this.inkoTerm"
          [rmaCollectList]="this.rmaCollectList" [rmaPrePayAddList]="this.rmaPrePayAddList" [rmaData]="this.rmaData"
          (setShippinglData)="getShippingInfo($event)">
        </app-billto-addressrma>
      </div>
    </div> -->
    <div class="section-container">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isShippingCollapsed
              ? 'accordion-toggle'
              : 'accordion-toggle-collapsed'
          "
          (click)="isShippingCollapsed = !isShippingCollapsed"
          [attr.aria-expanded]="!isShippingCollapsed"
          aria-controls="shippingDetails"
        >
          {{ 'titles.shipping' | cxTranslate }}
        </div>
        <hr />
        <app-shipping-detailsrma
          id="shippingDetails"
          [ngbCollapse]="isShippingCollapsed"
          [rmaShippingAddress]="this.rmaShippingAddress"
          [inkoTerm]="this.inkoTerm"
          [rmaCollectList]="this.rmaCollectList"
          [rmaPrePayAddList]="this.rmaPrePayAddList"
          [rmaData]="this.rmaData"
          [deliveryOption]="this.deliveryOption"
          (setShippinglData)="getShippingInfo($event)"
          (checkRMAShippingAddressVal)="checkRMAShippingAddressVal($event)"
        >
        </app-shipping-detailsrma>
      </div>
    </div>
    <div class="section-container">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isNotificationCollapsed
              ? 'accordion-toggle'
              : 'accordion-toggle-collapsed'
          "
          (click)="isNotificationCollapsed = !isNotificationCollapsed"
          [attr.aria-expanded]="!isNotificationCollapsed"
          aria-controls="notificationDetails"
        >
          {{ 'titles.notification' | cxTranslate }}
        </div>
        <hr />
        <app-notification-attachmentrma
          id="notificationDetails"
          [ngbCollapse]="isNotificationCollapsed"
          [rmaInvoiceMail]="this.invoiceMail"
          [orderAckMail]="this.orderAckMail"
          [invoiceConatactName]="this.invoiceConatactName"
          [invoiceContactNum]="this.invoiceContactNum"
          [orderAckContName]="this.orderAckContactName"
          [orderAckContPhone]="this.orderAckContactPhone"
          (setNotificationData)="getNotificationInfo($event)"
        >
        </app-notification-attachmentrma>
      </div>
    </div>
    <div class="section-container">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isComplianceCollapsed
              ? 'accordion-toggle'
              : 'accordion-toggle-collapsed'
          "
          (click)="isComplianceCollapsed = !isComplianceCollapsed"
          [attr.aria-expanded]="!isComplianceCollapsed"
          aria-controls="complianceDetails"
        >
          {{ 'titles.compliance' | cxTranslate }}
        </div>
        <hr />
        <app-compliance-questionrma
          id="complianceDetails"
          [ngbCollapse]="isComplianceCollapsed"
          (setComplianceData)="getComplianceInfo($event)"
        >
        </app-compliance-questionrma>
      </div>
    </div>
    <div class="section-container">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isEndUserDetailsCollapsed
              ? 'accordion-toggle'
              : 'accordion-toggle-collapsed'
          "
          (click)="isEndUserDetailsCollapsed = !isEndUserDetailsCollapsed"
          [attr.aria-expanded]="!isEndUserDetailsCollapsed"
          aria-controls="endUserDetails"
        >
          {{ 'titles.endUserDetails' | cxTranslate }}
        </div>
        <hr />
        <app-enduser-detailsrma
          id="endUserDetails"
          [ngbCollapse]="isEndUserDetailsCollapsed"
          [rmaEndUserAddress]="this.rmaEndUserAddress"
          [customerAccount]="this.customerAccount"
          [enduserTypeArray]="this.enduserTypeArray"
          (checkEnduserAddress)="checkEnduserAddress($event)"
          (EnduserType)="getEnduserType($event)"
        >
        </app-enduser-detailsrma>
      </div>
    </div>
  </div>
  <div class="col-md-4 sticky-container">
    <div [ngClass]="{ sticky: isSticky }">
      <ds-checkout-order-summary
        (checkTermNCondition)="isChecked($event)"
      ></ds-checkout-order-summary>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="address-search-spinner cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
