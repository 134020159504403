<div class="row my-profile" *ngIf="!loadingFlag; else loading">
  <div class="col-md-12 tabcontent-display-line">
    <div class="row custom-bottom-border">
      <div class="col-md-12">
        <h5 class="tabcontent-display-line-section-heading">
          {{ 'myProfile.shipToLabel' | cxTranslate }}
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 margin-top">
            <bh-dropdown
              class="shiptoDropdown"
              menuWidth="fluid"
              [menuItems]="shipItems"
              required="true"
              unselectable="false"
              label="{{ 'myProfile.shipto' | cxTranslate }}"
              [value]="shipping"
              [message]=""
              [attr.error]=""
              isSmall="true"
              (selected)="onChange($event, 'shipping')"
            >
            </bh-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 margin-top">
            <div class="help-message">
              {{ 'myProfile.decimalFormateLabel' | cxTranslate }}
            </div>
            <bh-dropdown
              menuWidth="fluid"
              [menuItems]="decimalItems"
              unselectable="false"
              label="{{ 'myProfile.thousandSeparator' | cxTranslate }}"
              [value]="decimal"
              [message]=""
              [attr.error]=""
              isSmall="true"
              (selected)="onChange($event, 'decimal')"
            >
            </bh-dropdown>
          </div>
        </div>

        <div class="row custom-border-top">
          <div class="col-12 button-group">
            <bh-button
              [attr.disabled]="showLoading ? true : false"
              [attr.loading]="showLoading ? true : false"
              type="primary"
              (click)="onSubmit()"
              small
              label="{{ 'myProfile.saveBtn' | cxTranslate }}"
            ></bh-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="address-search-spinner cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
