<div class="container quick-order-container">
  <div class="action-error" *ngIf="showObsoluteMsg">
    <i class="material-icons notification-icon link-anchor">notifications</i>
    <strong>{{ 'quickorder.cannotBeAddedToCart' | cxTranslate }}</strong>
  </div>
  <div class="action-error" *ngIf="showInvalidEntryMsg">
    <i class="material-icons notification-icon link-anchor">notifications</i>
    <strong>{{ 'quickorder.invalidPartCannotBeAdded' | cxTranslate }}</strong>
  </div>
  <div class="quick-order-header">
    <div class="left-side">
      <div class="order-text">{{ 'quickorder.quickorder' | cxTranslate }}</div>
      <div class="order-side-text" *ngIf="!textChange">
        {{ 'quickorder.convertProdIntoOrder' | cxTranslate }}
      </div>
      <div class="order-side-text" *ngIf="textChange">
        {{ 'quickorder.transformListIntoOrder' | cxTranslate }}
      </div>
    </div>
    <div class="right-side">
      <div class="addtocart">
        <div class="add-button track-button">
          <bh-button
            type="primary"
            label="ADD To Cart"
            class="add-cart"
            (click)="addToCart()"
            [attr.disabled]="!enableAddToCart || showLoader"
          ></bh-button>
        </div>
      </div>
    </div>
  </div>
  <div class="quick-order-body">
    <app-order-parts
      (dataEmitter)="handleData($event)"
      (partsCopied)="partsCopied($event)"
      (deleteRow)="deleteRow($event)"
    ></app-order-parts>
    <app-quick-order-products
      [sharedData]="sharedData"
      (enableAddToCart)="enableCart($event)"
      [deletedRow]="deletedRow"
    ></app-quick-order-products>
  </div>
  <div>
    <p class="explore-prods-nav-link">
      {{ 'quickorder.dontHaveSpecPartNumbers' | cxTranslate }}
      <a [href]="allProductsUrl">
        {{ 'quickorder.exploreAllProducts' | cxTranslate }}</a
      >
      <span class="material-icons explore-prods-arrow">north_east</span>
    </p>
  </div>
</div>
