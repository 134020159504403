<div class="total-container">
  <div class="header">
    <div class="left-items">
      <mat-icon>info</mat-icon>
      <h6>Confirm Navigation</h6>
    </div>
    <div class="right-items" (click)="closeModal()">
      <i class="material-icons">close</i>
    </div>
  </div>
  <div class="content">
    <p>The changes will not be saved.Do you wish to exit configuration?</p>
  </div>
  <div class="action-buttons" *ngIf="product$ | async as product">
    <a
      class="yes-btn"
      (click)="exitConfiguration()"
      [routerLink]="['/', productLine, 'product', product.code, product.name]"
      >Yes</a
    >
    <a (click)="closeModal()" class="no-btn">Not now</a>
  </div>
  <div></div>
</div>
