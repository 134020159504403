import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import moment from 'moment';
import { TranslationService } from '@spartacus/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CustomerAccountService } from '../../../../core/customer-account/customer-account.service';
import { AddressModelService } from '../../../../shared/components/address-model/address-model.service';
import { BreadcrumbService } from '../../../../shared/components/breadcrumb/breadcrumb.service';
import { BuyCheckoutService } from '../../../checkout/buy-checkout/service/buy-checkout.service';
@Component({
  selector: 'app-rma-confirmation',
  templateUrl: './rma-confirmation.component.html',
  styleUrls: ['./rma-confirmation.component.scss'],
})
export class RmaConfirmationComponent implements OnInit {
  istableCollapse: boolean = false;
  $subscription: Subscription;
  data: any;
  cartId;
  orderCode: string;
  requestTotal: any;
  orderconfirm: string;
  userAddressList$: Observable<any>;
  userAddressList: any;
  isPayerDisabled: boolean = false;
  currentCartSubscription: Subscription;

  private getActiveRoute: Subscription;
  constructor(
    private buycheckoutService: BuyCheckoutService,
    private translate: TranslationService,
    private breadCrumbService: BreadcrumbService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private customerAccService: CustomerAccountService,
    private addressModelService: AddressModelService,
    private activeCartFacade: ActiveCartFacade
  ) {}

  ngOnInit(): void {
    this.currentCartSubscription = this.activeCartFacade
      .getActiveCartId()
      .subscribe((cartId) => {
        this.cartId = cartId;
        this.getUserAddressList();
      });
    this.customerAccService.disableChangeAccount.next(false);
    this.getOrderCode();
    this.breadCrumbService.setBreadCrumbs([]);
    this.translate
      .translate('rma-confirmation.rmaConfirmTitle')
      .subscribe((res: string) =>
        this.breadCrumbService.setBreadcrumbTitle(res)
      );
  }
  getUserAddressList() {
    this.userAddressList$ = this.addressModelService.getPayerAddressList(
      this.cartId
    );
    this.userAddressList$.subscribe((res) => {
      this.userAddressList = res;
      if (res.addresses.length == 0) {
        this.isPayerDisabled = false;
      } else {
        this.isPayerDisabled = true;
      }
    });
  }

  getOrderCode() {
    this.$subscription = this.buycheckoutService
      .receiveData()
      .subscribe((value) => {
        if (value) {
          this.orderCode = value;
          this.orderConfirmationData(value);
        }
      });
    if (!this.orderCode) {
      this.getActiveRoute = this.activeRoute.params.subscribe((params) => {
        this.orderCode = params['id'];
        this.orderConfirmationData(this.orderCode);
      });
    }
  }

  orderConfirmationData(orderCode) {
    this.buycheckoutService.getOrderConfirmationData(orderCode).subscribe(
      (res) => {
        this.data = res;
        this.orderconfirm = moment(this.data.ordersList[0].created).format(
          'MMM Do YYYY, h:mm a'
        );
      },
      (error) => {}
    );
  }

  goToReturns() {
    this.router.navigate(['/rma-tracking']);
  }

  getRmaNumbers(orderList) {
    let orderNums = [];
    orderList.forEach((el) => {
      orderNums.push(el.returnNumber);
    });
    return orderNums;
  }

  ngOnDestroy() {
    this.customerAccService.disableChangeAccount.next(false);
    this.$subscription.unsubscribe();
    if (this.getActiveRoute) this.getActiveRoute.unsubscribe();
  }
}
