export enum FileSizes {
  KB = 'kb',
  MB = 'mb',
}

export enum FileProgressLayouts {
  BOX = 'box',
  LIST = 'list',
}

export interface CustomFile {}
