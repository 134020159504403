<ng-container>
  <div class="waygate-facets-container">
    <div class="facet-title">
      <h4>{{ 'waygate.productType' | cxTranslate }}</h4>
    </div>
    <div class="facet-body">
      <a
        *ngFor="let value of facet.filterValues; let i = index"
        #facetValue
        routerLink="./"
        class="waygate-facet-link"
        (click)="linkClicked(i)"
        [ngClass]="{
          selected: value.selected,
          'checkbox-disabled': value.selected && selectedArrayLength == 1,
        }"
      >
        <span>
          <span class="label">{{ value.name }}</span>
        </span>
      </a>
    </div>
  </div>
</ng-container>
