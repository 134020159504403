<div class="cx-modal-container">
  <div class="cx-modal-content">
    <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">
        <span>{{ 'rma-form.fillOutForm' | cxTranslate }}</span>
      </div>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal(false)"
      >
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="cx-dialog-body modal-body">
      <div class="row">
        <div class="cx-dialog-info col-md-12">
          <p>{{ 'rma-form.tellUsAboutMaterials' | cxTranslate }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 action-container">
          <bh-button
            [type]="'secondary'"
            [label]="'rma-form.updateNow' | cxTranslate"
            (click)="closeModal(true)"
          ></bh-button>
          <bh-button
            [type]="'primary'"
            [label]="'rma-form.updateLater' | cxTranslate"
            (click)="closeModal()"
          ></bh-button>
        </div>
      </div>
    </div>
  </div>
</div>
