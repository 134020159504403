import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  SecurityContext,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import {
  testRegex,
  REGULAR_PATTERN,
} from '../../../../../core/generic-validator/regular-expressions';
import { PlaceorderModel } from '../../../../checkout/buy-checkout/buy-checkout.model';
import { GuestBuyCheckoutService } from '../../../../checkout/guest-checkout/services/guest-buy-checkout.service';

@Component({
  selector: 'app-waygate-compliance-questions',
  templateUrl: './waygate-compliance-questions.component.html',
  styleUrls: ['./waygate-compliance-questions.component.scss'],
})
export class WaygateComplianceQuestionsComponent {
  @Output() setComplianceData: EventEmitter<any> = new EventEmitter();
  @Output() isComplianceValid: EventEmitter<any> = new EventEmitter();

  radiobtnbuy: any;
  submitted: boolean = false;
  $subscription: Subscription;

  buyCheckoutModel: PlaceorderModel;
  exportAddress = '';

  constructor(
    private chkService: GuestBuyCheckoutService,
    public sanitizer: DomSanitizer
  ) {}
  ngOnInit(): void {
    this.buyCheckoutModel = new PlaceorderModel();
    this.$subscription = this.chkService
      .getValidation()
      .subscribe((notification) => {
        if (notification) {
          this.onSubmit();
        }
      });
  }
  exportAddressVal() {
    if (this.radiobtnbuy == 'true' && this.exportAddress.trim()?.length != 0)
      this.isComplianceValid.emit(true);
    else {
      this.isComplianceValid.emit(false);
    }
  }
  handleChange(e, field) {
    if (field === 'radiobtnbuy') {
      this.radiobtnbuy = e.target.value;
      if (this.radiobtnbuy == 'false') {
        this.exportAddress = '';
        this.isComplianceValid.emit(true);
      } else if (
        this.radiobtnbuy == 'true' &&
        this.exportAddress.trim()?.length != 0
      ) {
        this.isComplianceValid.emit(true);
      } else {
        this.isComplianceValid.emit(false);
      }
    }
  }
  onSubmit() {
    this.submitted = true;
    if (this.radiobtnbuy == undefined) {
      // window.scrollTo({ top: 2000, behavior: 'smooth' });
    }
    if (this.radiobtnbuy) {
      this.buyCheckoutModel.govtAgencyFlagVal = this.radiobtnbuy
        ? this.radiobtnbuy.trim()
        : '';
      this.buyCheckoutModel.nuclearOpportFlagVal = this.radiobtnbuy
        ? this.radiobtnbuy.trim()
        : '';
      this.buyCheckoutModel.planToExportFlagVal = this.radiobtnbuy
        ? this.radiobtnbuy.trim()
        : '';
      this.buyCheckoutModel.isBuyerFlagVal = this.radiobtnbuy
        ? this.radiobtnbuy.trim()
        : '';
      this.buyCheckoutModel.googleCaptcha = '';
      let exprotValue = this.exportAddress;
      const rmaexportPattern = /^[\wÁ-ÿa-zA-Z0-9.+-/ ]+$/;
      if (exprotValue && !exprotValue.match(rmaexportPattern)) {
        exprotValue = '';
      }
      this.buyCheckoutModel.exportAddress = exprotValue;
      this.buyCheckoutModel.ndays = '';
      this.buyCheckoutModel.ndaysOfWeek = [];
      this.buyCheckoutModel.nthDayOfMonth = '';
      this.buyCheckoutModel.nweeks = '';
      this.buyCheckoutModel.requestedHdrDeliveryDate = '';
      this.buyCheckoutModel.replenishmentStartDate = '';
      this.buyCheckoutModel.replenishmentRecurrence = '';
      this.buyCheckoutModel.replenishmentOrder = false;
      this.buyCheckoutModel.securityCode = '';
      this.buyCheckoutModel.termsCheck = true;
      this.setComplianceData.emit(this.buyCheckoutModel);
    }
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
    this.chkService.setValidation(false);
  }
}
