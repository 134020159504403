<div class="row my-profile" *ngIf="!loadingFlag; else loading">
  <div class="col-md-12 tabcontent-display-line">
    <div class="row custom-bottom-border">
      <div class="col-md-12">
        <h5 class="tabcontent-display-line-section-heading">
          {{ 'myProfile.NotificationEmail' | cxTranslate }}
        </h5>
      </div>
      <div class="col-md-9 col-lg-9 col-sm-12">
        {{ 'myProfile.notificationPreference' | cxTranslate }}
      </div>
      <div class="col-md-3 col-lg-3 col-sm-12">
        <bh-selection-group false layout="horizontal" message="" false false>
          <bh-radio-button
            name="termsAgreementRadioGroup"
            value="true"
            checked
            label="{{ 'myProfile.yes' | cxTranslate }}"
          ></bh-radio-button>
          <bh-radio-button
            name="termsAgreementRadioGroup"
            value="false"
            label="{{ 'myProfile.no' | cxTranslate }}"
          >
          </bh-radio-button>
        </bh-selection-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 margin-top">
        <h5 class="tabcontent-display-line-section-transactional-heading">
          {{ 'myProfile.transactionalNotification' | cxTranslate }}
        </h5>
        <div class="row">
          <div class="col-md-5 margin-top">
            <div>{{ 'myProfile.orderAcknLabel' | cxTranslate }}</div>
            <bh-text-input
              type="text"
              small
              required
              [attr.error]="error.orderAcknEmail ? error.orderAcknEmail : null"
              placeholder="{{ 'myProfile.emailAddress' | cxTranslate }}"
              maxlength="40"
              [value]="orderAcknEmail"
              [message]="error.orderAcknEmail"
              (keyup)="onChange($event, 'orderAcknEmail')"
            ></bh-text-input>
          </div>

          <div class="col-md-4 margin-top-two">
            <bh-text-input
              type="text"
              small
              required
              [attr.error]="error.orderAcknName ? error.orderAcknName : null"
              placeholder="{{ 'myProfile.namePlaceholder' | cxTranslate }}"
              maxlength="40"
              [value]="orderAcknName"
              [message]="error.orderAcknName"
              (keyup)="onChange($event, 'orderAcknName')"
            ></bh-text-input>
          </div>

          <div class="col-md-3 margin-top-two">
            <bh-text-input
              type="text"
              small
              required
              [attr.error]="
                error.orderAcknContact ? error.orderAcknContact : null
              "
              placeholder="{{ 'myProfile.contactNoPlaceholder' | cxTranslate }}"
              maxlength="16"
              [value]="orderAcknContact"
              [message]="error.orderAcknContact"
              (keyup)="onChange($event, 'orderAcknContact')"
            ></bh-text-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5 margin-top">
            <div>{{ 'myProfile.invoiceEmailLabel' | cxTranslate }}</div>
            <bh-text-input
              type="text"
              small
              required
              [value]="invoiceEmail"
              [attr.error]="error.invoiceEmail ? error.invoiceEmail : null"
              placeholder="{{ 'myProfile.emailAddress' | cxTranslate }}"
              [message]="error.invoiceEmail"
              maxlength="40"
              (keyup)="onChange($event, 'invoiceEmail')"
            ></bh-text-input>
          </div>

          <div class="col-md-4 margin-top-two">
            <bh-text-input
              type="text"
              small
              required
              [value]="invoiceName"
              [attr.error]="error.invoiceName ? error.invoiceName : null"
              placeholder="{{ 'myProfile.namePlaceholder' | cxTranslate }}"
              [message]="error.invoiceName"
              maxlength="40"
              (keyup)="onChange($event, 'invoiceName')"
            ></bh-text-input>
          </div>

          <div class="col-md-3 margin-top-two">
            <bh-text-input
              type="text"
              small
              required
              [value]="invoiceContact"
              [attr.error]="error.invoiceContact ? error.invoiceContact : null"
              placeholder="{{ 'myProfile.contactNoPlaceholder' | cxTranslate }}"
              [message]="error.invoiceContact"
              maxlength="40"
              (keyup)="onChange($event, 'invoiceContact')"
            ></bh-text-input>
          </div>
        </div>
        <div class="row custom-border-top">
          <div class="col-12 cancel-button-group">
            <!-- <bh-button type="secondary" small label="{{ 'myProfile.cancelBtn' | cxTranslate }}">
                        </bh-button> -->
            <bh-button
              [attr.disabled]="showLoading ? true : null"
              [attr.loading]="showLoading ? true : null"
              type="primary"
              small
              label="{{ 'myProfile.saveBtn' | cxTranslate }}"
              (click)="onSubmit()"
            >
            </bh-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
<ng-template #loading>
  <div class="address-search-spinner cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
