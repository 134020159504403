export class FeedbackModel {
  subject: string;
  name: string;
  phoneNo: string;
  emailId: string;
  message: string;
  status: string;
  id: number;
  ticketCategory: string;
  googleCaptcha: string;
  attachmentId: string;
}
