export enum CartType {
  Typ1 = 'FILM',
  Typ2 = 'NONFILM',
  Typ3 = 'HYBRID',
}

export enum ProductType {
  Typ1 = 'IT',
  Typ2 = 'MS',
  Typ3 = 'ITFILM',
}
