<div class="row my-profile" *ngIf="!loadingFlag; else loading">
  <div class="col-md-12 tabcontent-display-line">
    <div class="row custom-bottom-border">
      <div class="col-md-12">
        <h5 class="tabcontent-display-line-section-heading">
          {{ 'myProfile.custmrAccntHeader' | cxTranslate }}
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h6 class="defaultOtherPrefLabel-title">
          {{ 'myProfile.changeyouraccount' | cxTranslate }}
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <bh-dropdown
              menuWidth="fluid"
              [menuItems]="customerAccItems"
              unselectable="false"
              label="{{ 'myProfile.cstmrAccntLabel' | cxTranslate }}"
              [message]=""
              [value]="customerAccount"
              [attr.error]=""
              isSmall="true"
              (selected)="onChange($event, 'customerAccount')"
            >
            </bh-dropdown>
          </div>
        </div>
        <div class="row margin-top">
          <div class="col-md-12">
            <bh-dropdown
              menuWidth="fluid"
              [menuItems]="bhBusinessItems"
              unselectable="false"
              label="{{ 'myProfile.bakerHughesLabel' | cxTranslate }}"
              [value]="bhBusiness"
              [message]=""
              [attr.error]=""
              isSmall="true"
              (selected)="onChange($event, 'bhBusiness')"
            >
            </bh-dropdown>
          </div>
        </div>

        <div class="row custom-border-top">
          <div class="col-12 button-group">
            <bh-button
              [attr.disabled]="showLoading ? true : null"
              [attr.loading]="showLoading ? true : null"
              type="primary"
              (click)="onSubmit()"
              small
              label="{{ 'myProfile.saveBtn' | cxTranslate }}"
            ></bh-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="address-search-spinner cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
