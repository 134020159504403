import { Action } from '@ngrx/store';
import { ProductCategory } from '../../model/product-categories.model';

export const FETCH_ALL_CATEGORIES =
  '[Product Categories Megamenu] Fetch All Product Categories';
export const FETCH_ALL_CATEGORIES_SUCCESS =
  '[Product Categories Megamenu] Fetch All Product Categories Success';
export const FETCH_ALL_CATEGORIES_FAIL =
  '[Product Categories Megamenu] Fetch All Product Categories Fail';

export class FetchAllProductCategories implements Action {
  readonly type = FETCH_ALL_CATEGORIES;
  constructor(public payload: string) {}
}

export class FetchAllProductCategoriesSuccess implements Action {
  readonly type = FETCH_ALL_CATEGORIES_SUCCESS;
  constructor(public payload: ProductCategory[]) {}
}

export class FetchAllProductCategoriesFail implements Action {
  readonly type = FETCH_ALL_CATEGORIES_FAIL;
  constructor(public payload: { error?: any }) {}
}

export type ProductCategoriesActions =
  | FetchAllProductCategories
  | FetchAllProductCategoriesSuccess
  | FetchAllProductCategoriesFail;
