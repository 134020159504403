export enum ProductSearchType {
  PART = 'part',
  SERIAL = 'serial',
}

export enum OfferTypes {
  REPAIR = 'Repair',
  RETURN = 'Return',
}

export enum ServiceOfferingCategories {
  REPAIR = 'SRV1',
  CALIBRATION = 'SRV2',
}
